import React from 'react';
import { graphql } from 'gatsby';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Seo from '../components/Seo';
import Layout from '../layout';
import CenteredContainer from '../components/CenteredContainer';
import FileCard from '../components/FileCard';
import MarkdownContent from '../components/MarkdownContent';

const PageTemplate = ({ data }) => {
  const { strapiPages } = data;

  return (
    <Layout offset={1}>
      <Seo title={strapiPages.naslov} />
      <CenteredContainer>
        <Box textAlign="center" mb={3}>
          <Typography variant="h2">{strapiPages.naslov}</Typography>
        </Box>
        <Box maxWidth={720} mt={2} mb={6}>
          <MarkdownContent children={strapiPages.sadrzaj} />
          {strapiPages.youtubeLink && (
            <Box display="flex" justifyContent="center" width={{ xs: 1, sm: 576, md: 720 }}>
              <Iframe
                src={strapiPages.youtubeLink}
                title="Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </Box>
          )}
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          {strapiPages.dokumenti.map(({ id, naslov, url, fajl }) => (
            <FileCard key={id} title={naslov} url={url} file={fajl.localFile.publicURL} />
          ))}
        </Box>
      </CenteredContainer>
    </Layout>
  );
};

const Iframe = styled.iframe`
  width: 100%;
  aspect-ratio: 1.77;
`;

export const query = graphql`
  query ($url: String!) {
    strapiPages(url: { eq: $url }) {
      id
      naslov
      sadrzaj
      url
      dokumenti {
        id
        naslov
        url
        fajl {
          localFile {
            publicURL
          }
        }
      }
      youtubeLink
    }
  }
`;

export default PageTemplate;
