import React from 'react';
import styled from '@emotion/styled';
import { Typography, Box, Button, Avatar, Paper } from '@mui/material';
import { FiFileText } from 'react-icons/fi';

const FileCard = ({ title, url, file }) => {
  return (
    <Box m={2}>
      <Paper elevation={2}>
        <FileCardContainer>
          <Box mb={3}>
            <Avatar color="primary">
              <FiFileText />
            </Avatar>
          </Box>
          <Typography variant="h6" align="center" gutterBottom>
            {title}
          </Typography>
          <Box mt="auto">
            <Button variant="contained" size="small" color="primary" href={file} target="_blank">
              {url}
            </Button>
          </Box>
        </FileCardContainer>
      </Paper>
    </Box>
  );
};

const FileCardContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  min-height: 280px;
  border-radius: 8px;
  ${({ theme }) => `
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
  .MuiAvatar-root {
      height: 120px;
      width: 120px;
      background-color: ${theme.palette.primary.light};
  }
  svg {
      font-size: 40px;
      color: ${theme.palette.primary.main};
  }
  `}
`;

export default FileCard;
